import '/assets/styles/pages/brands.scss';

import siteServices from '/services/site';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Partials
import Img from 'pstv-commerce-tools/partials/img';

const getBrands = () => {
	return siteServices.getFeaturedBrands()
}

const Brands = ({ pageProps }) => {
	const [brands, setBrands] = useState(pageProps?.brands ?? null);

	useEffect(() => {
		if(brands === null) {
			getBrands().then(brands => { setBrands(brands) });
		}
	}, [brands])

	return (
		<>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, mi eget varius bibendum, ligula massa dignissim sem, sed condimentum est leo at elit. Vestibulum mauris massa, tempus in ipsum ultrices, semper fermentum neque. Pellentesque ac tristique turpis. Vivamus eu congue urna. Praesent gravida, orci ac sodales semper, est urna scelerisque purus, vitae auctor nisi elit vel velit. Sed sed iaculis nunc, quis efficitur libero. In ac dictum libero. Duis ac ligula non dui pulvinar venenatis. Curabitur tincidunt tincidunt leo, vitae molestie sem placerat id. Vestibulum molestie elit non cursus semper. Sed vitae scelerisque ligula. Sed elementum ex orci, quis tempus nunc euismod a. Etiam risus lorem, consequat non enim eu, egestas posuere eros. Etiam fermentum feugiat blandit. Integer id porttitor risus. Integer quis tellus in ipsum aliquam ultrices eu a odio.</p>

			{brands ?
				<ul className="brands-list">
					{brands.map((brand, nth) => (
						<li key={nth}>
							<div className="brand-imagewrap">
								<Img
									src={brand.image}
									alt={brand.name}
									className="brand-image" />
							</div>
							<strong>{brand.name}</strong>
						</li>
					))}
				</ul>
				:
				<div>Markalar yükleniyor..</div>
			}
		</>
	)
}

Brands.propTypes = {
	children: PropTypes.object,
	pageProps: PropTypes.object
}

Brands.getServerProps = () => {
	return new Promise((resolve) => {
		Promise.all([
			getBrands()
		]).then(([brands]) => {
			resolve({
				pageProps: {
					brands: brands,
				},
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		});
	})
}

export default Brands
